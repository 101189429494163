import { Controller } from '@hotwired/stimulus';

export default class SignInUpController extends Controller {
  static outlets = ['modal'];

  static values = {
    currentTab: String,
    locale: Object,
  };

  static targets = [
    'modal',
    'signInUpForm',
    'fieldNationality',
    'fieldIdCardNumber',
    'termsAndConditionsElement',
    'forgotPasswordElement',
    'idCardInput',
    'emailInput',
    'passwordInput',
    'errorPanel',
  ];

  toggleTab() {
    const tabName = this.currentTabValue;
    switch (tabName) {
      case 'sign-in':
        // switch to sign-up tab
        this.currentTabValue = 'sign-up';
        this.updateFormUrl(`/${I18n.locale}/users`);
        this.toggleAdditoinalFields();
        this.showElement1AndHideElement2(
          this.termsAndConditionsElementTarget,
          this.forgotPasswordElementTarget
        );
        this.updateFormContent(
          this.localeValue.sign_up_form.title,
          this.localeValue.sign_in_form.already_have_account,
          this.localeValue.sign_in_form.sign_in
        );
        this.emailInputTarget.value = '';
        this.passwordInputTarget.value = '';
        this.idCardInputTarget.focus();
        break;

      case 'sign-up':
        // switch to sign-in tab
        this.currentTabValue = 'sign-in';
        this.updateFormUrl(`/${I18n.locale}/users/sign_in`);
        this.toggleAdditoinalFields();
        this.showElement1AndHideElement2(
          this.forgotPasswordElementTarget,
          this.termsAndConditionsElementTarget
        );
        this.updateFormContent(
          this.localeValue.sign_in_form.title,
          this.localeValue.sign_in_form.dont_have_account,
          this.localeValue.sign_in_form.sign_up
        );
        this.emailInputTarget.focus();
        break;
    }

    this.errorPanelTarget.classList.add('hidden');
  }

  updateFormUrl(url) {
    this.signInUpFormTarget.action = url;
  }

  toggleAdditoinalFields() {
    const currentTab = this.currentTabValue;
    if (currentTab == 'sign-in') {
      this.fieldNationalityTarget.classList.add('hidden');
      this.fieldIdCardNumberTarget.classList.add('hidden');
      this.fieldNationalityTarget.querySelector('select').setAttribute('disabled', '');
      this.fieldIdCardNumberTarget.querySelector('input').setAttribute('disabled', '');
    } else {
      this.fieldNationalityTarget.classList.remove('hidden');
      this.fieldIdCardNumberTarget.classList.remove('hidden');
      this.fieldNationalityTarget.querySelector('select').removeAttribute('disabled');
      this.fieldIdCardNumberTarget.querySelector('input').removeAttribute('disabled');
    }
  }

  showElement1AndHideElement2(element1, element2) {
    element1.classList.remove('hidden');
    element1.querySelectorAll('input').forEach(input => {
      input.removeAttribute('disabled');
    });

    element2.classList.add('hidden');
    element2.querySelectorAll('input').forEach(input => {
      input.setAttribute('disabled', '');
    });
  }

  updateFormContent(newTitle, newSubTitle, newBtnText) {
    const title = this.modalTarget.querySelector('.title');
    const subTitle = this.modalTarget.querySelector('.sub-title');
    const btnFormType = document.getElementById('btn-form-type');

    title.innerText = newTitle;
    subTitle.innerText = newSubTitle;
    btnFormType.innerText = newBtnText;
  }

  handleNationality(event) {
    this.setPlaceholderFromSelectedNationality(event.target.value);
  }

  setPlaceholderFromSelectedNationality(nationality) {
    const placeholderThaiId = this.modalTarget.dataset.placeholderThaiId;
    const placeholderPassport = this.modalTarget.dataset.placeholderPassport;

    this.idCardInputTarget.placeholder =
      nationality == 'TH' ? placeholderThaiId : placeholderPassport;
  }

  showModal({ params }) {
    this.modalOutlet.open(params.modalId);
  }
}
