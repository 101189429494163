import { Controller } from '@hotwired/stimulus';

export default class SelectNumberController extends Controller {
  static targets = [
    'recommendedNumbers',
    'categoryIcons',
    'number',
    'summaryNumber',
    'summaryCategory',
    'formNumber',
    'formCategories',
    'btnSubmit',
    'filterNumber',
    'noRecommendNumber',
    'spinnerBackdrop',
    'selectNumberPanel',
    'numberWrapper',
    'spinnerLoadNumbers',
  ];

  static values = {
    availableNumbers: Array,
    chosenCategories: Array,
    chosenNumber: String,
    chosenNumberCategories: Array,
    chosenNumberFormatted: String,
    keyword: String,
    nextPageNumber: Number,
    locale: Object,
  };

  connect() {
    this.hideBackdrop();
    this.perPage = 50;
    this.locale = I18n.locale;
    this.localDebounceTimeoutId = null;
    this.initChoosenNumber();
    this.#fetchNumbers();
  }

  localDebounce(callback, wait) {
    return (...args) => {
      clearTimeout(this.localDebounceTimeoutId);

      this.localDebounceTimeoutId = setTimeout(() => {
        callback.apply(this, args);
      }, wait);
    };
  }

  initChoosenNumber() {
    // In case of user click Back from OrderSummary page, the chosenNumber will have value.
    // This function process neccesay steps regarding the exist of the chosenNumber
    const localeLuckyCategories = this.localeValue.lucky_categories;
    if (this.chosenNumberValue != '') {
      if (this.chosenNumberCategoriesValue.length > 0) {
        // show the category name at category section in the summary card
        const content = this.chosenNumberCategoriesValue
          .filter(this.isAllowedCategory)
          .map(category => {
            const c = category.toLowerCase();
            return localeLuckyCategories[c];
          })
          .join(' / ');
        this.summaryCategoryTarget.textContent = localeLuckyCategories.lucky_number + ' ' + content;
        // marks chosen category icons
        this.chosenCategoriesValue = this.chosenNumberCategoriesValue.filter(
          this.isAllowedCategory
        );
        this.chosenCategoriesValue.forEach(c => {
          const iconLabel = this.categoryIconsTarget.querySelector(
            'label[for="' + c.toLowerCase() + '"]'
          );
          iconLabel.classList.add('selected');
          iconLabel.children[0].setAttribute('checked', 'checked');
        });
      } else {
        this.summaryCategoryTarget.textContent = localeLuckyCategories.normal_number;
      }

      this.summaryNumberTarget.textContent = this.chosenNumberFormattedValue;
      this.#updateFormNumber();
      this.#updateFormCategories();
    }
  }

  chooseCategory(event) {
    event.stopPropagation();
    event.preventDefault();

    const label = event.currentTarget;
    const inputCheckbox = label.children[0];
    const selectedCategoryName = label.getAttribute('for').toUpperCase();
    const selectedCategories = this.chosenCategoriesValue;
    this.nextPageNumberValue = 0;

    let newSelectedCategories;
    if (!inputCheckbox.hasAttribute('checked')) {
      inputCheckbox.setAttribute('checked', 'checked');
      label.classList.add('selected');
      newSelectedCategories = this.addItem(selectedCategories, selectedCategoryName);
    } else {
      inputCheckbox.removeAttribute('checked');
      label.classList.remove('selected');
      newSelectedCategories = this.removeItem(selectedCategories, selectedCategoryName);
    }
    this.chosenCategoriesValue = newSelectedCategories;
    //debounce 1000 ms is long enough to wait user to choose category, debounce 500 ms is too short.
    this.localDebounce(this.#fetchNumbers, 1000)();
  }

  chooseNumber(event) {
    event.stopPropagation();
    event.preventDefault();
    const number = event.currentTarget.querySelector('.number');
    const numberInput = event.currentTarget.querySelector('input');
    const numberCategories = numberInput.dataset.categories;
    const localeLuckyCategories = this.localeValue.lucky_categories;

    if (numberCategories === '') {
      this.chosenNumberCategoriesValue = [];
      this.summaryCategoryTarget.textContent = localeLuckyCategories.normal_number;
    } else {
      this.chosenNumberCategoriesValue = numberInput.dataset.categories.split(',');
      const content = this.chosenNumberCategoriesValue
        .filter(this.isAllowedCategory)
        .map(category => {
          const c = category.toLowerCase();
          return localeLuckyCategories[c];
        })
        .join(' / ');

      this.summaryCategoryTarget.textContent = localeLuckyCategories.lucky_number + ' ' + content;
    }
    // style selected number
    this.numberTargets.forEach(t => t.classList.remove('active'));
    number.classList.add('active');

    // update controller value
    this.chosenNumberValue = numberInput.value;
    this.chosenNumberValueFormatted = number.textContent;

    // show selected number at Order summary card
    this.summaryNumberTarget.textContent = number.textContent;

    this.btnSubmitTarget.classList.remove('disabled');

    this.#updateFormNumber();
    this.#updateFormCategories();
  }

  handleFilter() {
    this.numberTargets.forEach(number => {
      const regex = '[()-]*';
      const filterNumber = this.keywordValue.split('').join(regex);
      const matchFilter = number.textContent.match(filterNumber);
      const phoneNumberElem = number.closest('.phone-number');

      phoneNumberElem.classList.remove('hidden');
      number.innerHTML = number.textContent
        .replace(matchFilter[0], '<b class="highlight">$&</b>')
        .replace(/[(\)\-]/g, '<b class="text-gray-1">$&</b>');
    });
  }

  clearNoRecommendMessage() {
    this.noRecommendNumberTarget.classList.add('hidden');
  }

  showNoRecommendMessage() {
    let massage;
    let localeApiErrorAvailableNumber = this.localeValue.available_number;

    this.noRecommendNumberTarget.classList.remove('hidden');
    if (this.chosenCategoriesValue.length > 0 && this.filterNumberTarget.value !== '') {
      massage = localeApiErrorAvailableNumber.no_result_with_category_and_filter;
    } else if (this.filterNumberTarget.value !== '') {
      massage = localeApiErrorAvailableNumber.no_result_with_filter;
    } else if (this.chosenCategoriesValue.length > 0) {
      massage = localeApiErrorAvailableNumber.no_result_with_category;
    }
    this.noRecommendNumberTarget.textContent = massage;
  }

  filterNumberEvernt(event) {
    if (
      [9, 13, 27, 110, 190].includes(event.keyCode) ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && event.ctrlKey === true) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && event.ctrlKey === true) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && event.ctrlKey === true) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      return;
    }

    if (
      event.keyCode === 8 ||
      event.keyCode === 46 ||
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      (event.keyCode >= 96 && event.keyCode <= 105)
    ) {
      //debounce 1000 ms is long enough to wait user input filter numbers, debounce 500 ms is too short.
      this.localDebounce(this.#fetchNumbers, 1000)();
    } else {
      event.preventDefault();
    }
  }

  #fetchNumbers(isMustClearNumbers = true) {
    this.#disableFilterNumber();
    this.#disableAllCategoriesIcons();
    this.#showSpinner();
    if (isMustClearNumbers) {
      this.#clearDisplayNumbers();
    }

    this.clearNoRecommendMessage();
    this.keywordValue = this.filterNumberTarget.value;
    fetch(this.url, {
      method: 'get',
      headers: {
        'X-CSRF-Token': this.csrfToken,
      },
    })
      .then(resp => {
        if (!resp.ok) {
          throw new Error('Ops! not able to get numbers for the moment');
        }
        return resp.json();
      })
      .then(data => {
        this.isFetchNumberEnd = true;
        this.nextPageNumberValue = data.next_page_number;
        this.#hideSpinner();
        this.#enabledFilterNumber();
        this.#enableAllCategoriesIcons();

        if (data.error_message != null) {
          this.showNoRecommendMessage();
        }

        this.#displayNumbers(data);
        this.handleFilter();
        this.displayPageLink();

        this.availableNumbersValue = data.numbers.map(n => n.number);
      })
      .catch(() => {
        this.#hideSpinner();
        this.#enableAllCategoriesIcons();
      });
  }

  isAllowedCategory(name) {
    const availableCategories = ['LOVE', 'WEALTH', 'HEALTH', 'CAREER'];
    return availableCategories.includes(name);
  }

  addItem(list, name) {
    if (this.isAllowedCategory(name)) {
      list.push(name);
    }
    return list;
  }

  removeItem(list, name) {
    if (this.isAllowedCategory(name)) {
      return list.filter(item => item != name);
    } else {
      return list;
    }
  }

  #disableFilterNumber() {
    this.filterNumberTarget.disabled = true;
  }

  #disableAllCategoriesIcons() {
    this.categoryIconsTarget.classList.add('disabled');
  }

  #enabledFilterNumber() {
    this.filterNumberTarget.disabled = false;
  }

  #enableAllCategoriesIcons() {
    this.categoryIconsTarget.classList.remove('disabled');
  }

  #showSpinner() {
    this.spinnerLoadNumbersTarget.classList.remove('hidden');
  }

  #hideSpinner() {
    this.spinnerLoadNumbersTarget.classList.add('hidden');
  }

  #clearDisplayNumbers() {
    this.numberWrapperTarget.innerHTML = '';
  }

  #displayNumbers(data) {
    data.numbers.forEach(n => {
      const categories = n.categories == null ? '' : n.categories;
      const availableCategories = ['LOVE', 'WEALTH', 'CAREER', 'HEALTH'];
      let numberSumHtml = '';

      if (this.chosenCategoriesValue.length > 0) {
        this.numberWrapperTarget.classList.add('col-lucky-number');
        numberSumHtml += `
          <div class="flex gap-1.5 items-center">
            ${availableCategories
              .map(c => {
                return `
                  <div class="icon ${categories.includes(c) ? `icon-${c.toLowerCase()}` : ''} ">
                    <svg width="15px" height="15px">
                      <use xlink:href="#icon-${c.toLowerCase()}"/>
                    </svg>
                  </div>
                `;
              })
              .join('')}
            <div class="flex flex-col font-semibold text-center leading-tight">
              <span class="text-[0.6875rem]">${this.localeValue.index.score_text}</span>
              <span>${n.sum}</span>
            </div>
          </div>
        `;
      } else {
        this.numberWrapperTarget.classList.remove('col-lucky-number');
      }

      const numberBodyHtml = `
          <input type="radio" name="select_number[number]"
            value="${n.number}"
            data-formatted-number="${n.formatted_number}"
            data-categories=${n.categories || ''}>
          <em class="number font-medium text-center not-italic"
            data-select-number-target="number"
            >${n.formatted_number}</em>
        `;

      this.numberWrapperTarget.insertAdjacentHTML(
        'beforeend',
        `
          <div class="phone-number">
            <label
              class="radio flex justify-center items-center gap-3"
              data-action="click->select-number#chooseNumber"
              >
              ${numberBodyHtml}
              ${numberSumHtml}
            </label>
          </div>
        `
      );
    });
  }

  #updateFormNumber() {
    const number = this.chosenNumberValue;
    const formHiddenFieldNumber = `<input type="hidden" name="select_number[number]" value="${number}">`;
    this.formNumberTarget.innerHTML = formHiddenFieldNumber;
  }

  #updateFormCategories() {
    const categories = this.chosenNumberCategoriesValue;
    const formHiddenFieldCategories = categories
      .map(c => {
        return `<input type="hidden" name="select_number[number_groups][]" value="${c}">`;
      })
      .join('');
    this.formCategoriesTarget.innerHTML = formHiddenFieldCategories;
  }

  loadMoreNumbers() {
    if (this.isFetchNumberEnd) {
      this.isFetchNumberEnd = false;
      if (this.nextPageNumberValue !== 0) {
        let isMustClearNumbers = false;
        //debounce 500 ms is a reasonable time for infinite scroll.
        this.localDebounce(this.#fetchNumbers(isMustClearNumbers), 500)();
      }
    }
  }

  displayPageLink() {
    const mobileView = window.innerWidth < 768;
    const tabletAndDesktopView = window.innerWidth >= 768;

    if (mobileView) {
      window.addEventListener('scroll', () => {
        const bottomPosition = window.pageYOffset + window.innerHeight;
        const scrollHeight = document.documentElement.scrollHeight * 0.95;

        if (bottomPosition >= scrollHeight) {
          this.loadMoreNumbers();
        }
      });
    } else if (tabletAndDesktopView) {
      this.recommendedNumbersTarget.addEventListener('scroll', () => {
        let scrollToBottom =
          this.recommendedNumbersTarget.scrollTop + this.recommendedNumbersTarget.clientHeight >=
          this.recommendedNumbersTarget.scrollHeight;

        if (scrollToBottom) {
          this.loadMoreNumbers();
        }
      });
    }
  }

  getQueryStringForCategories() {
    return this.chosenCategoriesValue
      .map(c => `categories[]=${c}`)
      .toLocaleString()
      .replace(/\,/g, '&');
  }

  displayBackdrop() {
    const mainBackdrop = document.querySelector('.main-backdrop');
    mainBackdrop.classList.add('show');
    mainBackdrop.style.backgroundColor = 'var(--black-3)';
    this.spinnerBackdropTarget.classList.remove('hidden');
  }

  hideBackdrop() {
    const mainBackdrop = document.querySelector('.main-backdrop');
    mainBackdrop.classList.add('hidden');
    mainBackdrop.classList.remove('show');
    this.spinnerBackdropTarget.classList.add('hidden');
  }

  get url() {
    const baseUrl = '/api/v1/available_numbers';
    // prettier-ignore
    let result = `${baseUrl}?page=${this.nextPageNumberValue}&includes=${this.keywordValue}&per_page=${this.perPage}&locale=${this.locale}`;
    if (this.getQueryStringForCategories() != '') {
      result += `&${this.getQueryStringForCategories()}`;
    }
    return result;
  }

  get csrfToken() {
    return document.querySelector("meta[name='csrf-token']").content;
  }
}
