import { Controller } from '@hotwired/stimulus';

export default class PaymentMethodsController extends Controller {
  static outlets = ['modal'];

  static targets = [
    'btnSubmit',
    'modalLinepay',
    'packageInfoCollapse',
  ];

  enableSubmitButton() {
    this.btnSubmitTarget.classList.remove('disabled');
    this.btnSubmitTarget.value = this.btnSubmitTarget.dataset.labelEnabled;
  }

  togglePackageInfo(e) {
    const viewDetailBtn = e.currentTarget;
    viewDetailBtn.ariaExpanded = !(viewDetailBtn.ariaExpanded === 'true');

    this.packageInfoCollapseTarget.classList.toggle('show');
    if (this.packageInfoCollapseTarget.classList.contains('show')) {
      this.packageInfoCollapseTarget.style.height = `${this.packageInfoCollapseTarget.scrollHeight}px`;
    } else {
      this.packageInfoCollapseTarget.style.height = '0px';
    }
  }

  showModal({ params }) {
    this.modalOutlet.open(params.modalId);
  }
}
