import { Controller } from '@hotwired/stimulus';

export default class TransferNumberController extends Controller {
  static targets = [
    'numberStep',
    'pinCodeStep',
    'requestNumberInput',
    'requestPinInput',
    'dtnImg',
    'otherNumberImg',
    'dtnDescription',
    'otherDescription',
    'submitButton',
    'warningNumberMessage',
    'warningPinMessage',
  ];

  static values = {
    indicatorTransferNumber: String,
    indicatorSelectNumber: String,
  };

  connect() {
    this.switchNameIndicator(this.indicatorTransferNumberValue);
  }

  disconnect() {
    this.switchNameIndicator(this.indicatorSelectNumberValue);
  }

  switchNameIndicator(step) {
    const indicatorTarget = document.querySelector(
      '.progress-indicator .group:nth-child(2) .name-indicator'
    );
    indicatorTarget.textContent = step;
  }

  async verifyNumberAndHandleOperatorService() {
    var locale = window.location.pathname.split('/')[1];
    const requestNumber = this.requestNumberInputTarget.value;
    const url =
      '/api/v1/phone_number_operators/' + requestNumber + '?locale=' + locale;

    if (this.requestNumberInputTarget.value === '') {
      const warningMsg = I18n.t(
        'js.dashboard.payment.modal.pay_amount.required'
      );
      this.addWarningMessage(warningMsg);
      return;
    }

    const resp = await fetch(url, { method: 'get' });
    if (!resp.ok) {
      const error = await resp.json();
      this.addWarningMessage(error.error_description);
      return;
    }

    const data = await resp.json();
    localStorage.removeItem('lucky_categories');
    this.#showPinCodeStep();

    if (data.operator_code === '08') {
      this.#showDtnImg();
      this.#showDtnDescription();
    } else {
      this.#showOtherNumberImg();
      this.#showOtherDescription();
    }

    this.#setOperatorData(data.operator_code);
  }

  addWarningMessage(warningMsg) {
    this.requestNumberInputTarget.classList.add('warning');
    this.warningNumberMessageTarget.classList.remove('hidden');
    this.warningNumberMessageTarget.textContent = warningMsg;
  }

  #setOperatorData(operatorCode) {
    localStorage.setItem('operatorCode', operatorCode);
  }

  #showPinCodeStep() {
    this.numberStepTarget.classList.add('hidden');
    this.pinCodeStepTarget.classList.remove('hidden');
  }

  #showDtnImg() {
    this.dtnImgTarget.classList.remove('hidden');
  }

  #showOtherNumberImg() {
    this.otherNumberImgTarget.classList.remove('hidden');
  }

  #showDtnDescription() {
    this.dtnDescriptionTarget.classList.remove('hidden');
  }

  #showOtherDescription() {
    this.otherDescriptionTarget.classList.remove('hidden');
  }

  showNumberStep() {
    this.numberStepTarget.classList.remove('hidden');
    this.pinCodeStepTarget.classList.add('hidden');
  }

  enableSubmitButton(event) {
    if (event.currentTarget.value !== '') {
      this.submitButtonTarget.classList.remove('disabled');
    } else {
      this.submitButtonTarget.classList.add('disabled');
    }
  }

  hideWarningNumberMessage() {
    this.requestNumberInputTarget.classList.remove('warning');
    this.warningNumberMessageTarget.classList.add('hidden');
  }

  hideWarningPinMessage() {
    this.requestPinInputTarget.classList.remove('warning');
    this.warningPinMessageTarget.classList.add('hidden');
  }
}
