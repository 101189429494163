import { Controller } from '@hotwired/stimulus';

export default class PackageCardsController extends Controller {
  static targets = [
    'packageContainer',
    'packageCard',
    'extraDataTag',
    'extraMinuteTag',
    'nextButton',
    'previousButton',
  ];

  connect() {
    const body = document.querySelector('body');
    this.packageContainerGap = window
      .getComputedStyle(body)
      .getPropertyValue('font-size');
    this.clonePackageCard = this.packageContainerTarget.innerHTML;
    this.viewportState = '';
    this.addResizeListener();
    this.checkingViewport();
    this.animateEnd();
  }

  addMarginBottomForDataAndCallBox() {
    const packageCardGap = parseInt(this.packageContainerGap) / 2;
    const remarkData = document.querySelector('.remark-speed');
    const remarkCall = document.querySelector('.remark-call');
    const remarkDataHeight = remarkData.getBoundingClientRect().height;
    const remarkCallHeight = remarkCall.getBoundingClientRect().height;
    
    this.packageCardTargets.forEach(card => {
      let marginBottomData = 0;
      let marginBottomCall = 0;
      const dataDetailBox = card.querySelector('.card-details').children[0];
      const callDetailBox = card.querySelector('.card-details').children[1];

      const infiniteCard = card.querySelector('.infinity');
      const hasExtraDataOncard = card.querySelector('.extra-data');
      const hasExtraCallOncard = card.querySelector('.extra-minute');

      if (this.extraDataTagTargets.length >= 1 && !hasExtraDataOncard) {
        const extraDataTagHeight = this.extraDataTagTarget.clientHeight;
        marginBottomData += packageCardGap + extraDataTagHeight;
      }

      if (this.extraMinuteTagTargets.length >= 1 && !hasExtraCallOncard) {
        const extraCallTagHeight = this.extraMinuteTagTarget.clientHeight;
        marginBottomCall += packageCardGap + extraCallTagHeight;
      }

      if (infiniteCard) {
        marginBottomData += packageCardGap + remarkDataHeight;
        marginBottomCall += packageCardGap + remarkCallHeight;
      }

      dataDetailBox.style.marginBottom = `${marginBottomData}px`;
      callDetailBox.style.marginBottom = `${marginBottomCall}px`;
    });
  }

  removeMarginBottomForDataAndCallBox() {
    this.packageCardTargets.forEach(card => {
      const dataDetailBox = card.querySelector('.card-details').children[0];
      const callDetailBox = card.querySelector('.card-details').children[1];

      dataDetailBox.style.marginBottom = '0';
      callDetailBox.style.marginBottom = '0';
    });
  }

  addResizeListener() {
    window.addEventListener('resize', () => {
      this.checkingViewport();
    });
  }

  // prettier-ignore
  checkingViewport() {
    const tabletToDekstop = window.innerWidth >= 768;
    const lessThanTabletLandscape = window.innerWidth < 1024
    const betweenTabletLandscapeToLaptop = window.innerWidth >= 1024 && window.innerWidth < 1440
    const desktop = window.innerWidth >= 1440
    this.oneSlideStep = this.packageCardTarget.offsetWidth + 2 * parseInt(this.packageContainerGap);

    if (tabletToDekstop) {
      this.addMarginBottomForDataAndCallBox();
    } else {
      this.removeMarginBottomForDataAndCallBox();
    }

    if (lessThanTabletLandscape) {
      if (this.viewportState !== 'lessThanTabletLandscape') {
        this.viewportState = 'lessThanTabletLandscape';
        this.unsetPackageSlider();
      }
      return;
    }

    if (betweenTabletLandscapeToLaptop && this.packageCardTargets.length > 3) {
      this.newViewportState = 'landscapeTablet';
      this.packageContainerTarget.style.width = `${this.oneSlideStep * 3}px`;
      if(this.packageCardTargets.length < 5) {
        this.packageContainerTarget.innerHTML = this.packageContainerTarget.innerHTML + this.clonePackageCard;
      }
    } else if (desktop && this.packageCardTargets.length > 4) {
      this.newViewportState = 'desktop';
      this.packageContainerTarget.style.width = `${this.oneSlideStep * 4}px`;
      if(this.packageCardTargets.length < 6) {
        this.packageContainerTarget.innerHTML = this.packageContainerTarget.innerHTML + this.clonePackageCard;
      }
    }

    if (this.newViewportState !== this.viewportState && this.newViewportState !== undefined
    ) {
      this.viewportState = this.newViewportState;
      this.setPackageSlider();
      return;
    }
  }

  setPackageSlider() {
    let moveLastPackageToFirst = this.packageContainerTarget.lastElementChild;
    this.packageContainerTarget.lastElementChild.remove();
    this.packageContainerTarget.prepend(moveLastPackageToFirst);
    this.packageCardTargets.forEach(card => {
      card.style.transform = `translateX(-${this.oneSlideStep}px )`;
    });
    this.nextButtonTarget.classList.remove('hidden');
    this.previousButtonTarget.classList.remove('hidden');
  }

  unsetPackageSlider() {
    this.packageContainerTarget.innerHTML = this.clonePackageCard;
    this.packageContainerTarget.style.width = 'auto';
    this.packageCardTargets.forEach(card => {
      card.style.transform = 'translateX(0px)';
    });
    this.nextButtonTarget.classList.add('hidden');
    this.previousButtonTarget.classList.add('hidden');
  }

  next() {
    if (!this.isAnimateEnd()) return;

    this.isCardAnimated = false;
    this.animateCard({ duration: 500, step: 2 });

    setTimeout(() => {
      let moveFirstPackageToLast = this.packageContainerTarget
        .firstElementChild;
      this.packageContainerTarget.firstElementChild.remove();
      this.packageContainerTarget.appendChild(moveFirstPackageToLast);
      this.animateCard({ duration: 0, step: 1 });
      this.animateEnd();
    }, 500);
  }

  previous() {
    if (!this.isAnimateEnd()) return;

    this.isCardAnimated = false;
    this.animateCard({ duration: 500, step: 0 });

    setTimeout(() => {
      let moveLastPackageToFirst = this.packageContainerTarget.lastElementChild;
      this.packageContainerTarget.lastElementChild.remove();
      this.packageContainerTarget.prepend(moveLastPackageToFirst);
      this.animateCard({ duration: 0, step: 1 });
      this.animateEnd();
    }, 500);
  }

  animateCard({ duration, step }) {
    this.packageCardTargets.forEach(card => {
      card.style.transform = `translateX(-${this.oneSlideStep * step}px )`;
      card.style.transitionDuration = `${duration}ms`;
    });
  }

  animateEnd() {
    this.isCardAnimated = true;
  }

  isAnimateEnd() {
    return this.isCardAnimated;
  }
}
