import { Controller } from '@hotwired/stimulus';
import { getLocale } from '/app/javascript/shared/utils/get_locale';

export default class NavigationController extends Controller {
  static outlets = ['modal'];
  static targets = ['navMenu', 'howToBuyList', 'languageList', 'myAccountList'];

  connect() {
    this.mainBackdrop = document.querySelector('.main-backdrop');
    this.addClickEventToBody(this.navMenuTarget);
    this.setLocale();
  }

  addClickEventToBody(navElement) {
    this.body = document.querySelector('body');
    this.body.addEventListener('click', event =>
      this.handleNavAndDropdown(event, navElement)
    );
  }

  handleNavAndDropdown(event, navElement) {
    let clickedNav = navElement.contains(event.target);
    if (clickedNav) return;

    const navShow = this.navMenuTarget.classList.contains('show');
    if (navShow) {
      this.hideNavMenu();
    }

    this.hideHowToBuyList();
    this.hideLanguageList();
    this.hideMyAccountList();
  }

  toggleHowToBuyList(event) {
    this.howToBuyListTarget.classList.toggle('show');
    this.hideOtherDropdown(event);
  }

  toggleLanguageList(event) {
    this.languageListTarget.classList.toggle('show');
    this.hideOtherDropdown(event);
  }

  toggleMyAccountList(event) {
    this.myAccountListTarget.classList.toggle('show');
    this.hideOtherDropdown(event);
  }

  hideNavAndBackdropWhenResizeToDesktop() {
    const desktopView = window.innerWidth >= 1100;
    const navShow = this.navMenuTarget.classList.contains('show');

    if (desktopView && navShow) {
      this.hideNavMenu();
    }
  }

  hideOtherDropdown(event) {
    const currentElement = event.currentTarget.querySelector('ul').dataset
      .navigationTarget;

    switch (currentElement) {
      case 'howToBuyList':
        this.hideLanguageList();
        this.hideMyAccountList();
        break;

      case 'languageList':
        this.hideHowToBuyList();
        this.hideMyAccountList();
        break;

      case 'myAccountList':
        this.hideHowToBuyList();
        this.hideLanguageList();
        break;

      default:
        this.hideHowToBuyList();
        this.hideLanguageList();
        this.hideMyAccountList();
        break;
    }
  }

  hideNavMenu() {
    this.navMenuTarget.classList.remove('show');
    this.mainBackdrop.classList.remove('show');
  }

  hideHowToBuyList() {
    this.howToBuyListTarget.classList.remove('show');
  }

  hideLanguageList() {
    this.languageListTarget.classList.remove('show');
  }

  hideMyAccountList() {
    if (this.hasMyAccountListTarget) {
      this.myAccountListTarget.classList.remove('show');
    }
  }

  setLocale() {
    I18n.locale = getLocale();
  }

  showNavMenu(event) {
    event.stopPropagation();
    this.navMenuTarget.classList.add('show');
    this.mainBackdrop.classList.add('show');
  }

  showModal({ params }) {
    this.hideNavMenu();
    this.modalOutlet.open(params.modalId);
  }

  stopPropagateFromHowToBuyList(event) {
    event.stopPropagation();
  }
}
