import { Controller } from '@hotwired/stimulus';

export default class EditPasswordController extends Controller {
  static targets = [
    'passwordInput',
    'passwordConfirmationInput',
    'errorMessages',
  ];

  hideErrorsMessage() {
    this.passwordInputTarget.classList.remove('warning');
    this.passwordConfirmationInputTarget.classList.remove('warning');
    this.errorMessagesTarget.classList.add('hidden');
  }
}
