import { Controller } from '@hotwired/stimulus';

export default class ForgotPasswordController extends Controller {
  static targets = ['emailInput', 'errorPanel'];

  clearInputValue() {
    const emailInput = document.getElementById('forgot-password');
    emailInput.value = '';
  }

  hideErrorsMessage() {
    this.emailInputTarget.classList.remove('warning');
    this.errorPanelTarget.classList.add('hidden');
  }
}
