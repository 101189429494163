import { Controller } from '@hotwired/stimulus';

export default class PersonalInfoController extends Controller {
  static targets = [
    'genderBtn',
    'genderInput',
  ]

  static values = {
    profile: Object,
  }

  changeGender() {
    const btn = event.currentTarget
    this.setGender(btn)
    this.highlightGender(btn)
  }

  setGender(btn) {
    this.genderInputTarget.value = btn.dataset.value
  }

  highlightGender(btn) {
    if(btn == null || btn == undefined) { return }

    const css = ['border-2', 'border-primary-green']
    // clear existing highlight for all btn
    this.genderBtnTargets.forEach(t => t.classList.remove(...css))
    // highlight selected btn
    btn.classList.add(...css)
  }
}
