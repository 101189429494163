import { Controller } from '@hotwired/stimulus';

export default class OrderSummaryController extends Controller {
  static targets = ['offerTime'];

  static values = {
    offerTime: String,
    isFreeOrder: Boolean,
  };

  connect() {
    if (this.offerTimeValue !== '') {
      this.startOfferCounter();
    }

    if (this.isFreeOrderValue) {
      this.enableSubmitPaymentForm();
    }
  }

  enableSubmitPaymentForm() {
    const paymentMethods = document.querySelectorAll('.payment-accordian');
    const submitPaymentFormButton = document.getElementById('payment-submit')
    paymentMethods.forEach((paymentMethod) => {
      paymentMethod.classList.add('pointer-events-none');
      paymentMethod.classList.add('opacity-30');
    });
    submitPaymentFormButton.classList.remove('disabled');
    submitPaymentFormButton.value = submitPaymentFormButton.dataset.labelEnabled;
  }

  disableSubmitPaymentForm() {
    const paymentMethods = document.querySelectorAll('.payment-accordian');
    const submitPaymentFormButton = document.getElementById('payment-submit')
    paymentMethods.forEach((paymentMethod) => {
      paymentMethod.classList.remove('pointer-events-none');
      paymentMethod.classList.remove('opacity-30');
    });
    submitPaymentFormButton.classList.add('disabled');
    submitPaymentFormButton.value = submitPaymentFormButton.dataset.labelDisabled;
  }

  startOfferCounter() {
    let endTime = new Date(this.offerTimeValue).getTime();
    this.counter = setInterval(() => {
      this.setDisplayTime(endTime);
    }, 1000);
  }

  setDisplayTime(endTimeMilliSec) {
    let currentTime = new Date();
    const currentTimeMilliSec = currentTime.getTime();
    const diffMilliSec = endTimeMilliSec - currentTimeMilliSec;

    if (diffMilliSec < 0) {
      clearInterval(this.counter);
      this.offerTimeTarget.textContent = this.offerTimeTarget.dataset.expiresLabel;
      return;
    }

    let hours = Math.floor(
      (diffMilliSec % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((diffMilliSec % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((diffMilliSec % (1000 * 60)) / 1000);
    let displayTime = `${hours} : ${minutes} : ${seconds}`;

    this.offerTimeTarget.textContent = displayTime;
  }
}
