import { Controller } from '@hotwired/stimulus';

export default class ModalController extends Controller {
  static targets = ['modal'];

  connect() {
    this.mainBackdrop = document.querySelector('.main-backdrop');
    this.mainBackdrop.addEventListener('click', () => {
      this.close();
    });
  }

  open(modalId) {
    // ask body to fix its content behide the backdrop to able to scroll
    const body = document.querySelector('body');
    body.style.position = 'fixed';
    body.style.width = '100%';

    const modal = document.getElementById(modalId);
    modal.classList.remove('hidden');
    this.mainBackdrop.classList.add('show');
  }

  close() {
    // ask body to release its content behide the backdrop to able to scroll
    const body = document.querySelector('body');
    body.style.position = '';

    this.modalTargets.forEach(modal => {
      const modalShowing = !modal.classList.contains('hidden');
      if (modalShowing) {
        modal.classList.add('hidden');
      }
    });

    this.mainBackdrop.classList.remove('show');
    const navMenu = document.querySelector('.app-nav nav');
    navMenu.classList.remove('show');
  }
}
