import '@hotwired/turbo-rails/app/javascript/turbo';
import { Application } from '@hotwired/stimulus';

import NavigationController from './controllers/navigation_controller';
import ModalController from './controllers/modal_controller';
import PackageCardsController from './controllers/package_cards_controller';
import SelectNumberController from './controllers/select_number_controller';
import OrderSummaryController from './controllers/order_summary_controller';
import PaymentController from './controllers/payment_controller';
import PersonalInfoController from './controllers/personal_info_controller.js';
import TransferNumberController from './controllers/transfer_number_controller';
import SignInUpController from './controllers/sign_in_up_controller';
import ForgotPasswordController from './controllers/forgot_password_controller';
import EditPasswordController from './controllers/edit_password_controller';
import InternationalServicesController from './controllers/international_services_controller';

import './application_new_design_2022.scss';

window.Stimulus = Application.start();
Stimulus.register('navigation', NavigationController);
Stimulus.register('modal', ModalController);
Stimulus.register('package-cards', PackageCardsController);
Stimulus.register('select-number', SelectNumberController);
Stimulus.register('order-summary', OrderSummaryController);
Stimulus.register('payment', PaymentController);
Stimulus.register('personal-info', PersonalInfoController);
Stimulus.register('transfer-number', TransferNumberController);
Stimulus.register('sign-in-up', SignInUpController);
Stimulus.register('forgot-password', ForgotPasswordController);
Stimulus.register('edit-password', EditPasswordController);
Stimulus.register('international-services', InternationalServicesController);
